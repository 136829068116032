import React, { Component } from "react";
import { connect } from 'react-redux';
import { idCheck, logOut } from '../redux/auth.redux';
import { retrieveMyAccount } from '../redux/user.redux';
import { shipping_address_updated } from '../redux/cart.redux';
import { navigate } from 'gatsby';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { country_code3 } from '../components/countries'
import { ListItemText } from "@material-ui/core";


class MyAccountButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            dataFetched: false,
            data_synced: false,
        };
    }
    componentDidMount() {
        this.props.idCheck();
    }
    

    componentDidUpdate() {
        if (!this.props.user) {
            this.props.retrieveMyAccount();
        }
        
    }


    handleMenuOpen = event => {
		this.setState({ anchorEl:event.currentTarget });
	}

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
    }
    
    handleLogin = () => {
        this.handleMenuClose();
        navigate('/login');
    }

    handleMyAccount = () => {
        this.handleMenuClose();
        navigate('/account');
    }
    
    handleAdminDashboard = () => {
        this.handleMenuClose();
        navigate('/admin');
    }

	handleMyTicket = () => {
        this.handleMenuClose();
        navigate('/ticketDashboard');
    }

    handleSignup = () => {
        this.handleMenuClose();
        navigate('/signup');
    }

    handleLogout = () => {
        this.handleMenuClose();
        this.props.logOut();
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem('token');
            if (window.location.pathname === '/account') {
                navigate('/');
            }
            window.location.reload();
        }
		
    }

    handleDownloadPortal = () => {
        this.handleMenuClose();
        navigate('/downloads');
    }
    
    
    render() {
        const { anchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const itemStyle = {fontSize: '12px', fontWeight: 600, textTransform: 'uppercase', letterSpacing: '1px', padding: "8px 24px 8px 24px"};
        const desktopMenu = (
            <div>
                <button 
                    aria-owns={isMenuOpen ? 'material-appbar' : undefined} 
                    aria-haspopup='true' onClick={this.handleMenuOpen}
                    style = {{
                        fontFamily: `"Roboto", sans-serif`,
                        backgroundColor: 'transparent', 
                        color: anchorEl ?  '#6639ce' : 'black',
                        borderColor: 'transparent',
                        fontWeight: 600,
                        padding: 0,
                        margin: 0,
                        marginTop: '6px',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                        width: 'auto',
                        height: 'auto',
                        letterSpacing: '1px',
                        position: 'absolute',
                        right: this.props.rightPadding,
                    }}
                    >
                        Account
                    </button>
                <Menu
                    getContentAnchorEl = {null}
                    anchorEl = {anchorEl}
                    anchorOrigin = {{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin = {{ vertical: 'top', horizontal: 'center' }}
                    open = {isMenuOpen}
                    onClose = {this.handleMenuClose}
                >
                    {!this.props.auth.isLoggedIn ? <MenuItem onClick={this.handleLogin} style={itemStyle}> Login </MenuItem> : null }
                    {this.props.auth.isLoggedIn ? <MenuItem onClick={this.handleMyAccount} style={itemStyle}>Account Info</MenuItem> : null }
                    {this.props.auth.role === 'super' || this.props.auth.role === 'admin' ? <MenuItem onClick={this.handleAdminDashboard} style={itemStyle}>Admin Dashboard</MenuItem> : null}
                    {this.props.auth.isLoggedIn ? <Divider /> : null }
                    {this.props.auth.isLoggedIn ? <MenuItem onClick={this.handleDownloadPortal} style={itemStyle}>Download Portal</MenuItem> : null}
                    {/* <MenuItem onClick={this.handleMyTicket}>Supports</MenuItem> */}
                    {this.props.auth.isLoggedIn ? <MenuItem onClick={this.handleLogout } style={itemStyle}>Logout</MenuItem> : <MenuItem onClick={this.handleSignup} style={itemStyle}>Signup</MenuItem> }
                </Menu>
            </div>
        );
        const mobileMenu = (
            <React.Fragment>
                {!this.props.auth.isLoggedIn ? <ListItem button onClick={this.handleLogin}> <ListItemText style={{textTransform: 'uppercase'}}>Login</ListItemText> </ListItem> : null }
                {this.props.auth.isLoggedIn ? <ListItem button onClick={this.handleMyAccount}><ListItemText style={{textTransform: 'uppercase'}}>Account Info</ListItemText></ListItem> : null }
                {this.props.auth.role === 'super' || this.props.auth.role === 'admin' ? <ListItem button onClick={this.handleAdminDashboard}><ListItemText style={{textTransform: 'uppercase'}}>Admin Dashboard</ListItemText></ListItem> : null}
                {this.props.auth.isLoggedIn ? <ListItem button onClick={this.handleDownloadPortal}><ListItemText style={{textTransform: 'uppercase'}}>Download Portal</ListItemText></ListItem> : null}
                {this.props.auth.isLoggedIn ? <ListItem button onClick={this.handleLogout }><ListItemText style={{textTransform: 'uppercase'}}>Logout</ListItemText></ListItem> : <ListItem button onClick={this.handleSignup}><ListItemText style={{textTransform: 'uppercase'}}>Signup</ListItemText></ListItem> }
            </React.Fragment>
        )
        return (
            <React.Fragment>
                {this.props.isMobile ? mobileMenu : desktopMenu}
            </React.Fragment>
        )
    }
}

const mapStatetoProps = (state) => {return {auth: state.auth, user: state.user.data}};
const actionCreators = { idCheck, logOut, retrieveMyAccount, shipping_address_updated };
export default connect(mapStatetoProps, actionCreators)(MyAccountButton);
// export default MyAccountButton;