import React, { Component } from "react";
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartStyle from './cartComponents.module.css';
import { get_cart_from_storage, save_cart_to_storage } from '../redux/cart.redux';
import { navigate } from "gatsby";


class CartComponent extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.get_cart_from_storage();
    }

    componentWillUnmount() {
        this.props.save_cart_to_storage();
    }
    
    

    render() {
        const items = this.props.cart.items_in_cart;
        
        const amount_of_items = function() {
            var count = 0;
            for (var i=0; i < items.length; i++) {
                count += items[i].quantity;
            }
            return count;
        }
        const amount = amount_of_items();
        return (
            <div className={CartStyle.container} style={this.props.shouldHide ? {display: 'none'} : null} onClick={() => navigate('/cart')}>
                <div className={CartStyle.content}>
                    {amount > 0 ? <p style={{margin: '0', textAlign: 'center', fontWeight: 300}}>{amount}</p> : null}
                    <FontAwesomeIcon icon={faShoppingCart} size='lg'/>
                </div>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart}};
const actionCreators = { get_cart_from_storage, save_cart_to_storage }
export default connect(mapStatetoProps, actionCreators)(CartComponent);