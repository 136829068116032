import React, { useState } from "react"
import SocialIconsStyle from './SocialIcons.module.css'
import { SocialIcon } from 'react-social-icons'

function SocialIcons() {
    return (
        <div className={SocialIconsStyle.socialIconsContainer}>
          <div>
            <SocialIcon className={SocialIconsStyle.socialIcon} url="https://twitter.com/sonic_inst"/>
            <SocialIcon className={SocialIconsStyle.socialIcon} url="https://www.youtube.com/channel/UC29A-BPU84CITnZgmvDVx2w"/>
            <SocialIcon className={SocialIconsStyle.socialIcon} url="https://www.instagram.com/sonic_instruments"/>
            <SocialIcon className={SocialIconsStyle.socialIcon} url="https://www.facebook.com/SonicInstruments/"/>
            <SocialIcon className={SocialIconsStyle.socialIcon} url="https://linkedin.com/company/sonic-inst"/>
          </div>
        </div>
    )
}

export default SocialIcons