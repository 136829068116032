import React from 'react'
import { Link, navigate } from 'gatsby'

import LayoutStyles from './Layout.module.css'
import logo from '../assets/textLogo.png'
import graphicLogo from '../assets/logo.png'
import { FaBars as MenuIcon } from 'react-icons/fa'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { Menu, MenuItem, Grid } from '@material-ui/core';
import SocialIcons from './SocialIcons'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { connect } from 'react-redux'
import { logOut } from '../redux/auth.redux'
import CartComponent from '../components/cartComponent'
import MyAccountButton from '../components/myAccountBtn'
import { productMenu as productDirectory } from '../../Global_Config'
import NAMM from '../assets/NS20_Black_DATE.png'
class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {leftMenuIsOpen: false, productsIsOpen: false, anchorEl: null, isMobile: true}
    this.handleResize = this.handleResize.bind(this)
  }


  handleResize = e => {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
  }

  componentDidMount() {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
    window.addEventListener("resize", this.handleResize);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  showLeftMenu() {
    this.setState({leftMenuIsOpen: true})
  }

  toggleLeftDrawer = (open, link) => () => {
    this.setState(state => ({...state, leftMenuIsOpen: open}))
    if (link) {
      navigate(link)
    }
  }

  toggleProductsCollapse = () => {
    this.setState(state => ({...state, productsIsOpen: !state.productsIsOpen}))
  }

  toggleAccountCollapse = () => {
    this.setState(state => ({...state, accountIsOpen: !state.accountIsOpen}))
  }

  handleProductsClick = event => {
    const target = event.currentTarget
    this.setState(state=> ({...state, anchorEl: target}))
    
  }

  handleProductMenuClose = (link) =>() => {
    this.setState(state=>({...state, anchorEl: null}))
    if (link) {
      navigate(link)
    }
  }



  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const links = ['home', 'products', 'about', 'posts', 'contact']
    const products = productDirectory;
    const motionSubLinks = []
    const linkItems = links.map((link, i) => {
      if (link === 'products') {
        return (
          <div key='products'>
            <a href='javascript:void(0)' style={this.state.anchorEl ? {color:'#6639ce'} : {}} aria-owns={this.state.anchorEl ? 'simple-menu' : undefined} aria-haspopup='true' onClick={this.handleProductsClick}>
              products
            </a>
            <Menu id='simple-menu' marginThreshold={48} anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleProductMenuClose()} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }}>
              {products.map((link, i) => {
                const item = <MenuItem key={`productMenu${i}`} style={{fontSize:'12px', letterSpacing: '1px', padding: `8px 24px 8px 24px`, fontWeight:600, fontFamily: `'Roboto', sans-serif`, textTransform: 'uppercase'}} onClick={this.handleProductMenuClose(`/${link.url}`)}>{link.name}</MenuItem>
                if (link.sub === 0)
                {
                  if (i === 0)
                  {
                    return (item)
                  }
                  return (
                    <div>
                      <Divider/>
                      {item}
                    </div>
                  )
                }
                else
                {
                  return (item)
                }
                
              })}
            </Menu>
        </div>
          
        )
      } else if (link === 'home') {
        return <div className={LayoutStyles.home} key={link}><Link to={`/`} key={`link${i}`}>home</Link></div>
      } else {
        return <div key={link}><Link to={`/${link}`} key={`link${i}`}>{link}</Link></div>
      }
    })
    const productLinkItems = (
      products.map((link, i) => {
        if (link === 'motion-series') {
          var items = motionSubLinks.map((link, i) => {
            return <MenuItem key={`productSubMenu${i}`} style={{textTransform:'uppercase', fontSize:12, fontWeight:500, fontFamily: `'Roboto', sans-serif`, marginLeft:'12px'}} onClick={this.handleProductMenuClose(`/${link}`)}>{'- '+link}</MenuItem>
          })
          items.unshift(<MenuItem key={`productMenu${i}`} style={{textTransform:'uppercase', fontSize:12, fontWeight:600, fontFamily: `'Roboto', sans-serif`}} onClick={this.handleProductMenuClose(`/${link}`)}>{link}</MenuItem>)
          return (items)
        } else {
          const item = 
          <ListItem button key={`product${i}`} onClick={this.toggleLeftDrawer(false, `/${link.url}`)}>
            <ListItemText primary={`${link.name}`} />
          </ListItem>
          return (
            item
          )
        }
      })
    )
    const linkListItems = (
        <List style = {{width: '100%', maxWidth: 360}}>
          <ListItem>
            <img src={graphicLogo} style={{width:30}} alt='graphicLogo'/>
          </ListItem>
          <ListItem button onClick={this.toggleLeftDrawer(false, '/')}>
              <ListItemText style={{textTransform: 'uppercase'}} primary='home' />
          </ListItem>
          {links.map((link, i) => {
            if (link !== 'home' && link !== 'mo-band') {
              return (
                <ListItem button key={`button${i}`} onClick={link === 'products' ? this.toggleProductsCollapse : this.toggleLeftDrawer(false, `/${link}`)}>
                <ListItemText primary={link} style={{textTransform: 'uppercase'}}/>
                  {link === 'products' ? <Collapse in={this.state.productsIsOpen} timeout='auto' unmountOnExit><List component='div' disablePadding style={{textTransform:'uppercase', paddingLeft: '12px'}}>{productLinkItems}</List></Collapse>: null}
                </ListItem>
              )
            }
          })}
          {/* <ListItem button onClick={this.toggleLeftDrawer(false)}>
            <a href='https://forum.sonichits.co'>
              <ListItemText primary='forum' />
            </a>
          </ListItem> */}
          <ListItem button onClick={this.toggleAccountCollapse} style={{marginTop: '2rem'}}>
            <ListItemText style={{textTransform: 'uppercase'}}>Account</ListItemText>
            <Collapse in={this.state.accountIsOpen} timeout='auto' unmountOnExit>
              <MyAccountButton isMobile={this.state.isMobile}/>
            </Collapse>
          </ListItem>
        </List>
    )
    const drawer = (
      <Drawer open={this.state.leftMenuIsOpen} onClose={this.toggleLeftDrawer(false)} style={{opacity: 0.95}}>
        {linkListItems}
      </Drawer>
    )


    const header = (
      <nav className={LayoutStyles.navBar} id='navBar'>
        <div className={LayoutStyles.navBarContainer}>
          <div className={LayoutStyles.leftLinks}>
              {linkItems}
              <MyAccountButton isMobile={this.state.isMobile} rightPadding={this.props.showAddToCartOn ? '10rem' : '60px'}/>
              {this.props.showAddToCartOn ? <button className={LayoutStyles.preorderBtnDesktop} onClick={this.props.showAddToCartOn}>{this.props.itemName ? this.props.itemName : "Add to cart"}</button> : null}
              {/* <div><a href={'https://forum.sonichits.co'}>Forum</a></div> */}
          </div>
          <CartComponent shouldHide={this.props.hideCart}/>
          <div className={LayoutStyles.menuIcon}>
              <a href='javascript:;'>
                <MenuIcon size={20} onClick={this.toggleLeftDrawer(true)}/>
              </a>
          </div>
          <Link className={LayoutStyles.title} to={`/`}>
            {/* <img src={logo} className={LayoutStyles.logo} alt='logo' /> */}
            Sonic Instruments
          </Link>
          {this.props.showAddToCartOn ?<button className={LayoutStyles.mobilePreOrderBtn} onClick={this.props.showAddToCartOn}>{this.props.itemName ? this.props.itemName : "Add to cart"}</button> : null }
        </div>
      </nav>
      
    )

    
    return (
      <div>
        <div id='App'
          style={{
            height: '100vh'
          }}
        >
          {drawer}
          {header}
          {children}
          {this.props.hideSocial ? null : <SocialIcons/>}
          {this.state.isMobile ? 
            null : 
            <MessengerCustomerChat
              pageId="127442014519345"
              appId=""
              version='4.0'
            />
          }
          <footer className={LayoutStyles.footer}>
            {/* <div style={{maxWidth: '600px', margin:'auto'}}><img src={NAMM} alt="namm-show-2020" width='100%'/></div> */}
            <div>© {new Date().getFullYear()} Sonic Instruments, all rights reserved.</div>
          </footer>
        </div>
      </div>
    )
  }
}

const mapStatetoProps = (state) => {return {auth: state.auth, user: state.user.data}}
const actionCreators = { logOut }
export default connect(mapStatetoProps, actionCreators)(Layout)
